import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { TopStepProps } from './type'
import classnames from 'classnames'
import './index.less'
const TopStep: React.FC<TopStepProps> = ({ step_num, onBack }) => {
  const history = useHistory()

  return (
    <div className="top_banner">
      <div className="top-banner-container">
        <div className={classnames('step-line', step_num)}>
          <div
            className="back_btn"
            onClick={() => {
              if (onBack) {
                onBack(history)
              } else {
                history.goBack()
              }
            }}
          ></div>
        </div>

        <div className="text-line">
          <div className="step_text_container">
            <div className="step_content">选词</div>
            <div className="step_content">创作</div>
            <div className="step_content">完成</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopStep
