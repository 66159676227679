import axios from 'axios'
import { message } from 'antd'
import { baseUrl } from './baseUrl'
function requestConfig(method, url, params, token, headers, body) {
  let config = {
    method: method,
    url: `${baseUrl}${url}`,
    headers: headers || {},
    timeout: 20000,
  }
  if (typeof token !== 'boolean') token = true
  if (token)
    config.headers['Authorization'] = window.localStorage.getItem('token')
  if (params && !body)
    /^(get|delete|patch)$/.test(method)
      ? (config.params = params)
      : (config.data = params)
  if (body) config.data = params
  return axios(config)
    .then(res => {
      return res.data || 'ok'
    })
    .catch(err => {
      let response = err.response
      if (!response) {
        message.error('网络错误，请求数据失败')
        return 'err'
      }
      switch (true) {
        case response.status === 401:
          window.localStorage.removeItem('user')
          window.localStorage.removeItem('token')
          window.location.href = '/'
          message.error('登陆凭证过期')
          break
        default:
          message.error('网络错误 #500')
          break
      }
      return response.data || 'err'
    })
}
let http = {}
/**
 * @param {string} url 请求地址
 * @param {string} data 请求数据
 * @param {boolean} token 是否携带token，默认携带
 * @param {object} headers 请求头
 * @param {function} callback 回调函数
 */
http.post = async (url, data, { token, headers, callback }) => {
  requestConfig('post', url, data, token, headers).then(
    res => {
      if (typeof callback === 'function' && res) callback(res, undefined)
    },
    err => {
      if (typeof callback === 'function' && err) callback({}, err)
    }
  )
}

http.get = (url, params, { token, headers, callback, body }) => {
  requestConfig('get', url, params, token, headers, body).then(
    res => {
      if (typeof callback === 'function' && res) callback(res, undefined)
    },
    err => {
      if (typeof callback === 'function' && err) callback({}, err)
    }
  )
}
http.delete = (url, params, { headers, callback, body }) => {
  requestConfig('delete', url, params, true, headers, body).then(
    res => {
      if (typeof callback === 'function' && res) callback(res, undefined)
    },
    err => {
      if (typeof callback === 'function' && err) callback({}, err)
    }
  )
}
http.put = (url, data, { headers, callback, body }) => {
  requestConfig('put', url, data, true, headers, body).then(
    res => {
      if (typeof callback === 'function' && res) callback(res, undefined)
    },
    err => {
      if (typeof callback === 'function' && err) callback({}, err)
    }
  )
}
http.patch = (url, params, { headers, callback, body }) => {
  requestConfig('patch', url, params, true, headers, body).then(
    res => {
      if (typeof callback === 'function' && res) callback(res, undefined)
    },
    err => {
      if (typeof callback === 'function' && err) callback({}, err)
    }
  )
}
export default http
