import React, { useEffect, useState, useRef } from 'react'
import { Input, Table } from 'antd'
import { SearchPoetry, InitialPoetry } from '../../server/poetry'
import './index.less'
import { useHistory } from 'react-router-dom'
import EmptyData from '../EmptyData/index'
import { SearchOutlined } from '@ant-design/icons'
import PoetryIcon from '../PoetryIcon'

const { Search } = Input

const columns = [
  {
    title: <span className="table_header">标题</span>,
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => (
      <span className="table_body table_title">{text}</span>
    ),
  },
  {
    title: <span className="table_header">诗人</span>,
    dataIndex: 'author',
    key: 'author',
    render: (text: string) => (
      <span className="table_body table_author">{text}</span>
    ),
  },
  {
    title: <span className="table_header">诗词</span>,
    dataIndex: 'paragraphs',
    key: 'paragraphs',
    render: (text: string) => (
      <span className="table_body table_paragraphs">{text}</span>
    ),
  },
]
const fakeData = [
  {
    key: '',
    title: '',
    author: '',
    paragraphs: '',
    poet_serial_number: '',
  },
]

function setSelectPoetInfo(select_poet_info: any) {
  // const select_poet_info = JSON.stringify(select_poet_info)
  localStorage.setItem('select_poet_info', JSON.stringify(select_poet_info))
}

const SelectPoetry: React.FC = () => {
  const [data, setData] = useState({ poetrys: fakeData, total: 0, query: '' })
  const [inputValue, setInputValue] = useState('')
  const history = useHistory()
  const onSearch = (value: string) => {
    SearchPoetry(value, 1, (res: any) => {
      if (res.code === 200) {
        console.log(res.data)
        setData(res.data)
      }
    })
  }

  useEffect(() => {
    if (inputValue) {
      // onSearch(inputValue)
    } else {
      onSearch('')
    }
  }, [inputValue])

  useEffect(() => {
    InitialPoetry((res: any) => {
      if (res.code === 200) {
        console.log(res.data)
        setData(res.data)
      }
    })

    // 修改分页器显示文字
    const paginatePrevBtn = document.querySelector(
      '.ant-pagination-prev .ant-pagination-item-link'
    )
    const paginateNextBtn = document.querySelector(
      '.ant-pagination-next .ant-pagination-item-link'
    )
    if (paginatePrevBtn) {
      paginatePrevBtn.innerHTML = '上一页'
    }
    if (paginateNextBtn) {
      paginateNextBtn.innerHTML = '下一页'
    }
  }, [])

  const checkIsEmptyData = () => {
    if (!Array.isArray(data.poetrys)) {
      return true
    }

    if (data.poetrys.length < 1) {
      return true
    }
    return false
  }

  const hadEmptyData = checkIsEmptyData()
  return (
    <div className="select_poetry">
      {/* <Search
        className="search_box"
        placeholder="搜索 标题/诗人/诗词"
        allowClear
        onSearch={onSearch}
      /> */}
      <Input
        prefix={<PoetryIcon type="search" />}
        className="search_box"
        placeholder="搜索 标题/诗人/诗词"
        allowClear
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onPressEnter={e => {
          // @ts-ignore
          const value = e.target.defaultValue
          onSearch(value)
        }}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     if (restProps.onSearch) {
        //       restProps.onSearch(value);
        //     }
        //   }
        // }}
      />

      {hadEmptyData ? (
        <EmptyData />
      ) : (
        <Table
          columns={columns}
          onRow={record => {
            return {
              onClick: event => {
                console.debug('onrow click<', record, event)

                const paragraphsArr = record.paragraphs
                  .replace(/([，。？！])/g, '$1*')
                  .split('*')
                  .filter(_ => _)

                // window.location.href = `poetCreate.html?poet_serial_number=${record.poet_serial_number}&poet_info=${poet_info}`

                setSelectPoetInfo({
                  poet_serial_number: record.poet_serial_number,
                  poet_info: { ...record, paragraphs: paragraphsArr },
                  time: Date.now(),
                })

                window.location.href = `poetCreate.html`
              }, // 点击行
              onDoubleClick: event => {},
              onContextMenu: event => {},
              onMouseEnter: event => {}, // 鼠标移入行
              onMouseLeave: event => {},
            }
          }}
          pagination={{
            pageSize: 5,
            total: data.total,
            showSizeChanger: false,
            position: ['bottomLeft'],
            onChange: (page, pageSize) => {
              console.log(page)
              SearchPoetry(data.query, page, (res: any) => {
                if (res.code === 200) {
                  console.log(res.data)
                  setData(res.data)
                }
              })
            },
          }}
          dataSource={data.poetrys}
        />
      )}
    </div>
  )
}

export default SelectPoetry
