import './index.less'
import React from 'react'
import { PPoetryBackgroundProps } from './type'

const PoetryBackground: React.FC<PPoetryBackgroundProps> = ({ children }) => {
  return (
    <div className="poetry-background-wrapper">
      <div className="poetry-background-container"></div>
    </div>
  )
}

export default PoetryBackground
