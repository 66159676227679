import './index.less'
import React from 'react'
import { PPullIconProps } from './type'
import classnames from 'classnames'
const PullIcon: React.FC<PPullIconProps> = ({
  className = '',
  hidden = true,
  ...domAttrs
}) => {
  const _className = classnames('poetry-pull-icon-wrapper', className, {
    hidden,
  })
  return (
    <div className={_className} {...domAttrs}>
      <span className="poetry-pull-icon"></span>
    </div>
  )
}
export default PullIcon
