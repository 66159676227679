import './index.less'
import React from 'react'
import { PageHeaderProps } from './type'
import { Link } from 'react-router-dom'
import { Divider, Space } from 'antd'
import defaultLogo from '../../assets/pageHeader/linglogo.png'
// import defaultLogo from '../../assets/pageHeader/logo.png';

export const adminPageID = 'dt25-admin-head'

const PageHeader: React.FC<PageHeaderProps> = ({ title = '' }) => {
  return (
    <div className="pageheader-container" id={adminPageID}>
      <div className="page-header">
        <Link className="pageheader-left" to="/" title={title}>
          <img src={defaultLogo} alt="" />
          {/* <TextContainer
            className="pageheader-title"
            text={title}
            maxOverflowLine={1}
            // maxLineFont={8}
          /> */}
        </Link>

        <div className="pageheader-right">
          AI诗歌作曲系统
          <Space
            split={<Divider type="vertical" />}
            className="pageheader-right-area"
          ></Space>
          <Space
            split={<Divider type="vertical" />}
            className="pageheader-right-actions"
          >
            {/* {actions} */}
          </Space>
        </div>
      </div>
    </div>
  )
}
export default PageHeader
