import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import { PPlayDrawerProps, PDetailDataProps } from './type'
import { WorksDetail } from '../../server/music'

import PlayControl from '../PlayControl/index'
import Poetry from '../Poetry/index'

import './index.less'

const PlayDrawer: React.FC<PPlayDrawerProps> = ({
  onClose,
  visible,
  song_id,
  outsidePlay,
}) => {
  const [detailData, setDetailData] = useState<PDetailDataProps>({
    midi_url: '',
    poetry: { author: '', paragraphs: '', title: '' },
    song_url: '',
    duration: 0,
  })

  useEffect(() => {
    WorksDetail(song_id, (res: any) => {
      if (res.code === 200) {
        console.log(res.data)
        setDetailData(res.data)
      }
    })
  }, [song_id])

  return (
    <Drawer
      getContainer={false}
      destroyOnClose={true}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width="785px"
      style={{ position: 'absolute' }}
    >
      <Poetry data={detailData.poetry} scrollStyle={{ height: 384 }} />
      <div className="close_btn" onClick={onClose}></div>
      <PlayControl
        song_url={detailData.song_url}
        song_duration={detailData.duration}
        outsidePlay={outsidePlay}
      />
    </Drawer>
  )
}

export default PlayDrawer
