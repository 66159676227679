import React, { useEffect, useState } from 'react'

import './index.less'
import TopStep from '../../components/TopStep/index'
import SelectPoetry from '../../components/SelectPoetry/index'

// import poetCreate from './poetCreate.html'

const Create: React.FC = () => {
  return (
    <div className="creact_page">
      <TopStep step_num="step_2" />


      {/* <iframe
        title="resg"
        srcDoc={poetCreate}
        style={{ width: '100%', border: '0px', height: '1100px' }}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        scrolling="auto"
      /> */}
    </div>
  )
}

export default Create
