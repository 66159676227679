import http from "./axios";

//获取搜索数据
export function SearchPoetry(query, page, callback) {
  http.get("/hifive_hi_api/poetry/search", { query, page }, { callback });
}
//获取初始数据
export function InitialPoetry(callback) {
  http.get("/hifive_hi_api/poetry/search", {}, { callback });
}
