import React from 'react'
import { PWorksListItemProps } from './type'
import classnames from 'classnames'
import './index.less'
const WorksListItem: React.FC<PWorksListItemProps> = ({
  hits,
  music_bg,
  music_title,
  detail,
}) => {
  return (
    <div className="works_list_item">
      <div className={classnames('music_box', music_bg)}>
        <div className="music_listen">
          <span className="music_listen_number">{hits} 万</span>
        </div>
        <div className="music_play_btn" onClick={detail}></div>
      </div>
      <div className="music_title">{music_title}</div>
      <div className="music_footer">
        <div className="title_bottom_border"></div>
      </div>
    </div>
  )
}

export default WorksListItem
