import './index.less'
import React from 'react'

const Footer: React.FC = () => {
  const logout = () => {
    localStorage.clear()
  }

  return (
    <div className="poetry-footer-wrapper">
      <div className="footer-copyright">
        Copyright ©{new Date().getFullYear()}
      </div>
      <div className="footer-text">
        成都灵跃云创科技有限公司 网站地图 蜀ICP备20023943号
      </div>
    </div>
  )
}

export default Footer
