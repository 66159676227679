import './index.less'
import React, { useEffect, useRef, useState } from 'react'
import { PScrollContainerProps } from './type'
import classnames from 'classnames'
const defaultScroll = { x: 'auto', y: 250 }

// 检测是否溢出
function checkOverflow(el: HTMLElement) {
  var curOverflow = el.style.overflow
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden'
  var isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
  el.style.overflow = curOverflow
  return isOverflowing
}

const ScrollContainer: React.FC<PScrollContainerProps> = ({
  children,
  openScroll = true,
  scroll,
  className,
  style,
}) => {
  const _scroll = { ...defaultScroll, scroll }
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [hoverShow, setHoverShow] = useState(false)
  useEffect(() => {
    if (containerRef.current) {
      // 是否显示
      const isScroll = checkOverflow(containerRef.current)
      setHoverShow(isScroll && openScroll)
    }
  }, [children])

  const _className = classnames('scroll-container', className, {
    'hover-show-scroll': hoverShow,
  })
  return (
    <div ref={containerRef} className={_className} style={style}>
      {children}
    </div>
  )
}

export default ScrollContainer
