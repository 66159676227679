let baseUrl
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    baseUrl = 'https://xiaohisg-qa.xingzheai.cn/'
    break
  case 'qa':
    baseUrl = 'https://xiaohisg-qa.xingzheai.cn/'
    break
  case 'prod':
    baseUrl = 'https://xiaohisg.xingzheai.cn'
    break
  default:
    baseUrl = 'https://xiaohisg-qa.xingzheai.cn/'
    break
}

export { baseUrl }
