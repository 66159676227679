import React from 'react'
import './App.less'
import { HashRouter, Route } from 'react-router-dom'

import Index from './pages/Index/index'
import Create from './pages/Create/index'
import Select from './pages/Select/index'
// import Header from "./components/Header/index";
import Footer from './components/Footer/index'
import Finish from './pages/Finish/index'

import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd'

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        {/* <HashRouter>
        <Header />
      </HashRouter> */}
        <div className="container">
          <HashRouter>
            <Route path="/" exact component={Index} />
            <Route path="/select" component={Select} />
            <Route path="/create" component={Create} />
            <Route path="/finish" component={Finish} />
          </HashRouter>
        </div>
        {/* <HashRouter>
        <Footer />
      </HashRouter> */}
      </div>
    </ConfigProvider>
  )
}
export default App
