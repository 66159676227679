import React from 'react'

import { words } from 'lodash'

import { PPoetryProps } from './type'
import './index.less'
import ScrollContainer from '../ScrollContainer'

const Poetry: React.FC<PPoetryProps> = ({ data, scrollStyle }) => {
  const { title, author, paragraphs } = data

  const showPoetry = () => {
    let paragraphsList = words(paragraphs, /[^，。 ！？]+/g)

    return paragraphsList.map((item, index) => {
      if (index % 2 === 0) {
        return <p key={index}>{`${item}，`}</p>
      } else {
        return <p key={index}>{`${item}。`}</p>
      }
    })
  }
  return (
    <div className="poetry">
      <div className="poetry_title">{title}</div>
      <div className="poetry_author">{author}</div>
      <ScrollContainer className="poetry_content" style={scrollStyle}>
        {showPoetry()}
      </ScrollContainer>
    </div>
  )
}

export default Poetry
