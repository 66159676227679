import './index.less'
import React from 'react'
import { PPoetryIconProps, PPoetryIconGroupProps } from './type'
import classNames from 'classnames'
import { Tooltip, Space, Form, Divider } from 'antd'

const PoetryIcon: React.FC<PPoetryIconProps> = ({
  selected = false,
  disabled = false,
  type = 'none',
  children,
  tooltipProps,
  ...domAttributes
}) => {
  const _className = classNames('poetry-icon', type, { selected, disabled })
  const _onClick = disabled
    ? undefined
    : (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        domAttributes.onClick && domAttributes.onClick(e)
      }

  const defaultRender = (
    <span {...domAttributes} className={_className} onClick={_onClick}>
      {children}
    </span>
  )

  if (tooltipProps && tooltipProps.title) {
    return <Tooltip {...tooltipProps}>{defaultRender}</Tooltip>
  }

  return defaultRender
}

export const PoetryIconGroup: React.FC<PPoetryIconGroupProps> = ({
  children,
  hasSplit = false,
}) => {
  return (
    <Space
      className="poetry-icon-group-wrapper"
      split={hasSplit ? <Divider type="vertical" /> : null}
    >
      {children}
    </Space>
  )
}

export default PoetryIcon
