import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import TopStep from '../../components/TopStep/index'

import { WorksDetail, ComposeComplete } from '../../server/music'

import './index.less'
import PlayControl from '../../components/PlayControl/index'
import Poetry from '../../components/Poetry/index'
import PtyLoading from '../../components/PtyLoading'
import PoetryBackground from '../../components/PoetryBackground/index'
type TQueryParams = {
  poet_compose_id?: string
  [key: string]: any
}

function setFinishData(finish_data: any) {
  // const finish_data = JSON.stringify(finish_data)
  localStorage.setItem('finish_data', JSON.stringify(finish_data))
}

function setSelectPoetInfo(select_poet_info: any) {
  // const select_poet_info = JSON.stringify(select_poet_info)
  localStorage.setItem('select_poet_info', JSON.stringify(select_poet_info))
}

function getFinishData(): any {
  try {
    var info = localStorage.getItem('finish_data')
    if (!info) {
      return null
    }

    var selectInfo = JSON.parse(info)
    if (!selectInfo) {
      return null
    }
    return selectInfo
  } catch (e) {
    return null
  }
}
function getSelectPoetInfo(): any {
  try {
    var info = localStorage.getItem('select_poet_info')
    if (!info) {
      return null
    }

    var selectInfo = JSON.parse(info)
    if (!selectInfo) {
      return null
    }
    return selectInfo
  } catch (e) {
    return null
  }
}

const Finish: React.FC = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [outsidePlay, setOutsidePlay] = useState(false)

  const [finsihImageSrc, setFinsihImageSrc] = useState('')
  const [song_serial_number, setSong_serial_number] = useState('')
  const [finishData, setFinishData] = useState({
    duration: 39.344,
    midi_url: '',
    poetry: { author: '', paragraphs: '', title: '' },
    song_url: '',
    poet_compose_id: '',
  })

  // 获取跳转过来的数据
  const getQueryVariable = (): TQueryParams => {
    const hrefArr = window.location.href.split('?')
    let params = {}
    if (Array.isArray(hrefArr) && hrefArr.length === 2) {
      const key_values = hrefArr[1].split('&')
      key_values.map(key_val => {
        let key_val_arr = key_val.split('=')
        // @ts-ignore
        params[key_val_arr[0]] = key_val_arr[1]
      })
    }
    return params
  }
  // let poet_compose_id = getQueryVariable()
  // console.log('poet_compose_id', poet_compose_id)
  // useEffect(() => {
  //   let poet_compose_id = getQueryVariable()
  //   ComposeComplete(poet_compose_id, (res: any) => {
  //     setSong_serial_number(res.poet_compose_id)
  //     setIsLoading(false)
  //   })
  // }, [window.location.href])

  useEffect(() => {
    const params = getSelectPoetInfo()

    console.debug('params<', params)
    if (params && params.poet_compose_id) {
      // ComposeComplete(params.poet_compose_id, (res: any) => {
      //   if (res && res.poet_compose_id) {
      //     setSong_serial_number(res.poet_compose_id)
      //     setIsLoading(false)
      //   }
      // })

      const finishData = getFinishData()
      if (finishData) {
        console.debug('finishData<', finishData)
        setIsLoading(true)
        setFinsihImageSrc(finishData.finishBase64)

        setTimeout(() => {
          setFinishData(finishData)
          setIsLoading(false)
          setOutsidePlay(true)
        }, 2000)
      } else {
        history.push('/select')
      }
    } else {
      history.push('/select')
    }
  }, [])

  // useEffect(() => {
  //   if (song_serial_number) {
  //     WorksDetail(song_serial_number, (res: any) => {
  //       if (res.code === 200) {
  //         console.log(res.data)
  //         setFinishData(res.data)
  //       }
  //     })
  //   }
  // }, [song_serial_number])
  const downloadMp3 = () => {
    window.open(finishData.song_url)
  }
  const downloadMidi = () => {
    window.open(finishData.midi_url)
  }

  return (
    <div className="creact_page">
      <PoetryBackground />
      <TopStep
        step_num="step_3"
        onBack={() => {
          // const finishData = history.location.search
          // console.debug('url<', url)
          const url = `poetCreate.html?poet_compose_id=${finishData.poet_compose_id}`
          window.location.href = url
        }}
      />
      <div className="finish_works">
        {/* {isLoading ? <PtyLoading mode="black" text="创作中..." /> : null} */}

        <div
          className={isLoading ? 'left_door' : 'left_door left_door_open'}
        ></div>
        <div
          className={isLoading ? 'right_door' : 'right_door right_door_open'}
        ></div>

        {isLoading ? (
          <div className="finish-image">
            <img src={finsihImageSrc} alt="" />
          </div>
        ) : null}

        <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          <Poetry
            data={finishData.poetry}
            scrollStyle={{ height: 240, marginLeft: 24 }}
          />
          <PlayControl
            song_url={finishData.song_url}
            song_duration={finishData.duration}
            outsidePlay={outsidePlay}
          />
          <div className="btns_list">
            <div className="bottom_btn" onClick={downloadMp3}>
              下载音频
            </div>
            <div className="bottom_btn" onClick={downloadMidi}>
              导出midi
            </div>
            <div
              className="bottom_btn"
              onClick={() => {
                history.push('/')
              }}
            >
              回到首页
            </div>
          </div>
        </span>
      </div>
    </div>
  )
}

export default Finish
