import './index.less'
import React from 'react'
import { PEmptyDataProps } from './type'

const EmptyData: React.FC<PEmptyDataProps> = ({
  text = '曲库暂无数据，请重新搜索',
}) => {
  return (
    <div className="poetry-empty-data">
      <span className="empty-data-icon"></span>
      <span className="empty-data-text">{text}</span>
    </div>
  )
}

export default EmptyData
