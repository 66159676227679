import React, { useState, useRef, useEffect } from 'react'
import { message, Slider } from 'antd'
import { PPlayControlProps } from './type'

import './index.less'

const PlayControl: React.FC<PPlayControlProps> = ({
  song_url,
  song_duration,
  outsidePlay,
}) => {
  //音频播放状态
  const [isAudioPlay, setIsAudioPlay] = useState(false)
  //音频当前播放时间
  const [timeValue, setTimeValue] = useState(0)
  //音频当前显示播放时间
  const [timeDisplay, setTimeDisplay] = useState(0)

  //一个普通的audio标签
  const audioElementRef = useRef<HTMLVideoElement | null>(null)

  //时间轴
  const timeCount = () => {
    //用秒数来显示当前播放进度
    if (audioElementRef.current) {
      setTimeDisplay(Math.floor(audioElementRef.current.currentTime))
      setTimeValue(audioElementRef.current.currentTime / song_duration)
    } //获取实时时间
  }
  const onChange = (value: number) => {
    if (isNaN(value)) {
      return
    }
    setCurrentTime(value)
  }
  // const onPlay = () => {
  //   if (audioElementRef.current) {
  //     // audioElementRef.current. = song_url;
  //   }
  // };

  const _onPlay = () => {
    if (audioElementRef.current) {
      try {
        return audioElementRef.current.play()
      } catch (err) {
        message.info('请点击播放按钮播放音频')
      }
    }
  }
  const _onPlayOrPause = () => {
    if (audioElementRef && audioElementRef.current && !isAudioPlay) {
      _onPlay()
      console.debug('设定计时器')
      audioElementRef.current.addEventListener('timeupdate', timeCount)
      setIsAudioPlay(true)
    } else {
      audioElementRef.current?.pause()
      console.debug('移除计时器')
      audioElementRef.current?.removeEventListener('timeupdate', timeCount)
      setIsAudioPlay(false)
    }
  }
  const setCurrentTime = (time: any) => {
    setIsAudioPlay(true)
    if (audioElementRef && audioElementRef.current) {
      console.debug('当前时间')
      audioElementRef.current.currentTime = time * song_duration
      _onPlay()
    }

    setTimeValue(time)
  }
  const _secondsFormat = (sec: number) => {
    let minute: any = Math.floor(sec / 60)
    let second: any = sec - minute * 60

    if (minute < 10) {
      minute = '0' + minute
    }
    if (second < 10) {
      second = '0' + second
    }
    return minute + ':' + second
  }
  const onPlayEnded = () => {
    setIsAudioPlay(false)
  }

  useEffect(() => {
    const audioElement = audioElementRef.current
    if (audioElement) {
      const playPromise = audioElement.play().catch(err => {
        console.debug('playcontainer is safair#3')
      })
    }

    return () => {
      const audioElement = audioElementRef.current
      if (audioElement) {
        audioElement.pause()
        audioElement.removeEventListener('timeupdate', timeCount)
        audioElement.removeEventListener('ended', onPlayEnded)
        audioElement.removeEventListener('timeupdate', timeCount)
      }
    }
  }, [])

  useEffect(() => {
    const audioElement = audioElementRef.current
    if (audioElement) {
      audioElement.src = song_url
      if (outsidePlay) {
        audioElement
          .play()
          .then(() => {
            console.debug('play yes')
            setIsAudioPlay(true)
          })
          .catch(() => {
            console.debug('play no')
            setIsAudioPlay(false)
          })
        console.debug('设定计时器')
        audioElement.addEventListener('timeupdate', timeCount)
        audioElement.addEventListener('ended', onPlayEnded)
      }
    }
  }, [song_url, outsidePlay])
  return (
    <div className="play_control">
      <div
        className={isAudioPlay ? 'pause_btn' : 'play_btn'}
        onClick={_onPlayOrPause}
      ></div>
      <div className="progress">
        <div className="progress_line">
          <Slider
            tooltipVisible={false}
            min={0}
            max={1}
            onChange={onChange}
            value={typeof timeValue === 'number' ? timeValue : 0}
            step={0.0001}
          />
        </div>
        <div className="progress_time">
          <span className="current_time">{_secondsFormat(timeDisplay)}</span>
          <span className="duration">
            {_secondsFormat(Math.ceil(song_duration))}
          </span>
        </div>
      </div>
      <audio ref={audioElementRef}>浏览器暂时不支持</audio>
    </div>
  )
}

export default PlayControl
