import React, { useState, useEffect } from 'react'
import { Carousel } from 'antd'
import { useHistory } from 'react-router-dom'
import { PoetrysType } from './type'
import { IndexShow } from '../../server/index'

import WorksListItem from '../../components/WorksListItem/index'
import PlayDrawer from '../../components/PlayDrawer/index'

import './index.less'
import Footer from '../../components/Footer'
import PullIcon from '../../components/PullIcon/index'
import { debounce } from 'lodash'

import image1 from '../../assets/images/UI/banner1@2x.jpg'
import image2 from '../../assets/images/UI/banner2@2x.jpg'
import PageHeader from '../../components/PageHeader'

/********************
 * 取窗口滚动条高度
 ******************/
function getScrollTop() {
  var scrollTop = 0
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop
  } else if (document.body) {
    scrollTop = document.body.scrollTop
  }
  return scrollTop
}
/********************
 * 取窗口可视范围的高度
 *******************/
function getClientHeight() {
  var clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    var clientHeight =
      document.body.clientHeight < document.documentElement.clientHeight
        ? document.body.clientHeight
        : document.documentElement.clientHeight
  } else {
    var clientHeight =
      document.body.clientHeight > document.documentElement.clientHeight
        ? document.body.clientHeight
        : document.documentElement.clientHeight
  }
  return clientHeight
}
/********************
 * 取文档内容实际高度
 *******************/
function getScrollHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight
  )
}

const Index: React.FC = () => {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [outsidePlay, setOutsidePlay] = useState(false)
  const [songId, setSongId] = useState('')
  const [showPullIcon, setShowPullIcon] = useState(true)
  const [poetrys, setPoetrys] = useState<PoetrysType[]>([
    {
      hits: '',
      song_name: '',
      song_serial_number: '',
    },
  ])

  const showDrawer = (song_id: string) => {
    setVisible(true)
    setSongId(song_id)
    setOutsidePlay(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    IndexShow((res: any) => {
      if (res.code === 200) {
        // console.log(res.data.poetrys)
        setPoetrys(res.data.songs)
      }
    })

    // fullScreen()

    window.addEventListener('scroll', e => {
      if (getScrollTop() + getClientHeight() == getScrollHeight()) {
        changePullIcon(false)
      } else {
        changePullIcon(true)
      }
    })
  }, [])

  const changePullIcon = debounce((show: boolean) => {
    setShowPullIcon(show)
  }, 200)

  const fullScreen = () => {
    // 全屏
    try {
      if (null == document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      }
    } catch (e) {}
  }

  const scrollToBottom = () => {
    const t = document.body.clientHeight

    window.scroll({ top: t, left: 0, behavior: 'smooth' })
  }

  return (
    <div className="index_page">
      <PageHeader />
      {/* 顶部轮播图 */}
      <div className="index_banner">
        <Carousel autoplay dots={false}>
          <div>
            <div className="index_banner_image1"></div>
            {/* <img className="index_banner_image1" src={image1} /> */}
          </div>
          <div>
            <div className="index_banner_image2"></div>
            {/* <img className="index_banner_image2" src={image2} /> */}
          </div>
        </Carousel>
      </div>
      {/* 内容区域 */}
      <div className="index_content">
        <div className="works">
          <div className="works_title">
            <div className="title_text">优秀作品展示</div>
            <div className="title_underline"></div>
          </div>
          <div className="works_list">
            {poetrys.length &&
              poetrys.map((item, index) => {
                return (
                  <WorksListItem
                    key={index}
                    music_bg={`music_box${index + 1}`}
                    hits={item.hits}
                    music_title={item.song_name}
                    detail={() => showDrawer(item.song_serial_number)}
                  />
                )
              })}
          </div>

          <PullIcon
            hidden={!showPullIcon}
            onClick={() => {
              scrollToBottom()
              setShowPullIcon(false)
            }}
          />
        </div>
        {/* 创作按钮 */}
        <div className="btn_box">
          {' '}
          <div
            className="music_creact_btn"
            onClick={() => {
              history.push('/select')
            }}
          >
            立即创作
          </div>
        </div>
        <Footer />
      </div>

      <PlayDrawer
        onClose={onClose}
        visible={visible}
        song_id={songId}
        outsidePlay={outsidePlay}
      />
    </div>
  )
}

export default Index
