import React, { useEffect, useState } from 'react'

import './index.less'
import TopStep from '../../components/TopStep/index'
import SelectPoetry from '../../components/SelectPoetry/index'
import PoetryBackground from '../../components/PoetryBackground'

const Select: React.FC = () => {
  return (
    <div className="creact_page">
      <PoetryBackground />
      <TopStep step_num="step_1" onBack={h => h.push('/')} />
      <SelectPoetry />
    </div>
  )
}

export default Select
