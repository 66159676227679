import http from "./axios";

//获取
export function WorksDetail(song_serial_number,callback) {
  http.get("/hifive_hi_api/hi_production_detail", {song_serial_number}, { callback });
}
//完成

export function ComposeComplete(poet_compose_id,callback) {
  http.get("/hifive_hi_api/hi_poet_complete_compose", {poet_compose_id}, { callback });
}